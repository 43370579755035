import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Inner from '../../components/templates/Inner';
import { Divider, Box, Flex, Text } from '../../components/atoms';
import FootNote from '../../components/molecules/FootNote';
import img01 from '../../assets/img/design-tips/layout/01.png';
import img02 from '../../assets/img/design-tips/layout/02.png';
import img03 from '../../assets/img/design-tips/layout/03.png';
import img04 from '../../assets/img/design-tips/layout/04.png';
import img05 from '../../assets/img/design-tips/layout/05.png';
import img06 from '../../assets/img/design-tips/layout/06.png';
import { designTips as nav } from '../../nav';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Inner nav={nav} title="Layout" mdxType="Inner">
      <h3>{`Layout`}</h3>
  <Text maxWidth={560} mdxType="Text">
    Having a grid is nice. We build components and the main layout using an 8px grid.
    In some cases, align icons and typography by a 4px grid.
  </Text>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Surround it with lines`}</h4>
      <p>{`  Vertical basic lines help you determine the content area.`}</p>
  <Flex my="xxl" mdxType="Flex">
    <Box width={320} mr={152} mdxType="Box">
      <Box mdxType="Box"><img src={img04} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">
        Nicely fit inside the basice lines.
      </Box>
    </Box>
    <Box width={320} mdxType="Box">
      <Box mdxType="Box"><img src={img03} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">
        Content goes well beyond beyond the basic lines.
      </Box>
    </Box>
  </Flex>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Give some air`}</h4>
  <Text maxWidth={560} mdxType="Text">
    Legible and readable content is vital. Keeping the consistency in the distance
    between elements is a must.
  </Text>
  <Flex my="xxl" mdxType="Flex">
    <Box width={320} mr={152} mdxType="Box">
      <Box mdxType="Box"><img src={img02} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">
        Extra space helps user to read and understand copy.
      </Box>
    </Box>
    <Box width={320} mdxType="Box">
      <Box mdxType="Box"><img src={img01} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">
        Someone squeezed everything. Noone is going to read it.
      </Box>
    </Box>
  </Flex>
  <Text maxWidth={560} mdxType="Text">
    Add an 8px step spacing between basic lines and spaces between elements.
    This creates a consistent content display.
  </Text>
  <Flex mt="xxl" mdxType="Flex">
    <Box width={376} mr={152} mdxType="Box">
      <Box mdxType="Box"><img src={img06} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">
        8px step spacing applied. Everything looks consistent.
      </Box>
    </Box>
    <Box width={376} mdxType="Box">
      <Box mdxType="Box"><img src={img05} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">
        Vertical distance is inconsistent, 8px step missed.
      </Box>
    </Box>
  </Flex>
    </Inner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      